<template>
  <div class="shop">
    <div class="information">
      <!-- 全部 -->
      <van-search v-model="keyword"
        show-action
        label="关键词"
        placeholder="请输入姓名/电话/身份证/"
        @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <van-list :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad">
        <div class="center"
          v-for="item in xqlist"
          :key="item.index"
          style="font-size: 17px;border:1px solid rgb(222 206 206);">
          <!-- finished-text="没有更多了" -->
          <div @click="xiangqing(item)">
            <div class="shopTitle van-ellipsis"
              style="padding-bottom: 0">
              {{ item.Name }}<span style="margin-left:10px;">{{ item.Mobile }}</span>
            </div>
            <div class="Content"
              style="padding: 8px 10px">
              <div style="display: inline-block">
                <span style="color: #333"
                  class="van-multi-ellipsis--l3">{{ item.IDCard }}</span>
                <div style="font-size: 17px; color: #333; margin: 5px 0">
                  报备时间：{{ item.RegTime | filteryear}}
                </div>
                <div style="font-size: 17px; color: #333; margin: 5px 0">
                  状态：{{ item.StatusDesc }}
                </div>

              </div>
            </div>
          </div>
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { GetIsolationPage } from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
export default {
  data () {
    return {
      riKind: 0, //tab栏
      xqlist: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        keyword: '',
      }, //查询传参
      keyword: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  filters: {
    filteryear: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, 10);

    },
  },
  methods: {
    // 详情
    xiangqing (row) {
      console.log(row);
      var itemmark = JSON.stringify(row);
      this.$router.push({
        name: 'gkXiangQing', params: { itemmark }
      })
    },
    //新增
    addShop () {
      this.$router.push({
        name: "addEnt",
        query: { bfaId: this.itemmark, add: 1 },
      });
    },
    //重新绑定
    shopBangDing (shopId) {
      this.$router.push({
        name: "addEnt",
        query: { shopId: shopId, add: 3 },
      });
    },
    //编辑
    shopUpdata (shopId) {
      console.log(shopId);
      this.$router.push({
        name: "addEnt",
        query: { shopId: shopId, add: 2 },
      });
    },
    // 获取分页列表
    // 获取小区(组)分页列表
    getList () {
      var newlist = []
      //   this.listfrom.riKind = this.riKind;
      // this.listfrom.title = this.search;
      // this.listfrom.title = this.listfrom.title;
      // this.listfrom.organCode = this.listfrom.organCode;
      // this.listfrom.accToken = getwgToken()

      this.listfrom.accToken = getwgToken();
      GetIsolationPage(this.listfrom)
        .then((res) => {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束
          this.loading = false;

          if (res.data.code == 0) {
            console.log(rows);
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.finished = true;
              return;
            } else {
              this.xqlist = this.xqlist.concat(rows);
              //如果列表数据条数>=总条数，不再触发滚动加载
              if (this.xqlist.length >= res.data.count) {
                this.finished = true;
              }
            }

          }
        })
        .catch(() => { });
    },

    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.listfrom.keyword = this.keyword
      this.xqlist = []
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>